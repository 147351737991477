import { Measurements } from "./Measurements";

interface ResultsProps {
  measurements: Measurements;
}
export const Results: React.FC<ResultsProps> = (props) => {
  const params = new URLSearchParams(window.location.search);
  const callback = params.get("callback");
  if (callback) {
    let url = new URL(callback);
    url.searchParams.append("measurements", JSON.stringify(props.measurements));
    window.location.href = url.toString();
    return <p>Redirecting!</p>;
  }
  return (
    <ul>
      {Object.entries(props.measurements).map(([key, value]) => (
        <li>
          {key}: {value}
        </li>
      ))}
    </ul>
  );
};
