import { useState } from "react";
import pantsImage from "./images/onboarding/pants.png";
import paperImage from "./images/onboarding/paper.png";
import onboardingVideo from "./images/onboarding/onboarding.mp4";
import { css } from '@emotion/css';

const styles = {
  pill: css({
    textAlign: 'center',
    backgroundColor: 'black',
    fontWeight: 'bold',
    color: 'white',
    borderRadius: '3rem',
    width: '85%',
    margin: '10px',
    height: '3rem',
    border: 'none'
  }),
  onboardingVideo: css({
    width: '100%'
  }),
  onboardingInstructions: css({
    margin: '3rem'
  }),
};

interface CheckableInstructionProps {
  imageSource: string;
  text: string;
  onClick: () => void;
}

const CheckableInstruction: React.FC<CheckableInstructionProps> = (props) => {
  return (
    <div>
      <img src={props.imageSource} className={"CheckableImage"} alt={""} />
      <p>{props.text}</p>
      <input type="checkbox" onClick={props.onClick} />
    </div>
  );
};

interface OnboardingProps {
  onReady: () => void;
}

export const Onboarding: React.FC<OnboardingProps> = (props) => {
  let [pants, setPants] = useState(false);
  let [paper, setPaper] = useState(false);
  return (
    <div>
      <video playsInline autoPlay loop muted className={ styles.onboardingVideo }>
        <source src={onboardingVideo} type="video/mp4"></source>
      </video>
      <div className={ styles.onboardingInstructions }>
        <h1>Ready to start?</h1>
        <p>
          Please follow the instruction below to make sure we see your feet and
          ankles clearly.
        </p>
        <CheckableInstruction
          imageSource={pantsImage}
          text={"Take of shoes & roll up your pants"}
          onClick={() => setPants(!pants)}
        />
        <CheckableInstruction
          imageSource={paperImage}
          text={"Have a white a4 paper ready in your hand"}
          onClick={() => setPaper(!paper)}
        />
        <button
          disabled={!(pants && paper)}
          onClick={props.onReady}
          className={ styles.pill }
          style={{ background: (pants && paper) ? 'black' : '#e6e6e6' }}
        >
          Get started!
        </button>
      </div>
    </div>
  );
};
