import mixpanel, { Mixpanel } from "mixpanel-browser";

export class MixpanelUsageTracker {
  private tracker: Mixpanel | null = null;
  private apiKey = "18d73011923cf6369f1687faabcafab1";

  constructor() {
    mixpanel.init(this.apiKey, {
      ignore_dnt: true,
      loaded: (mixpanel) => {
        this.tracker = mixpanel;
      },
    });
  }

  track = (event: UsageTrackerEvent) => {
    this.tracker?.track(event);
  };
}

export enum UsageTrackerEvent {
  paperDetected = "PAPER_DETECTED",
  scannerInitialized = "SCANNER_INITIALIZED",
  scannerShotTaken = "SCANNER_SHOT_TAKEN",
  reconstructionFailure = "RECONSTRUCTION_FAILURE",
  reconstructionStarted = "RECONSTRUCTION_STARTED",
  reconstructionSuccess = "RECONSTRUCTION_SUCCESS",
}

const usageTracker = new MixpanelUsageTracker();
export default usageTracker;
