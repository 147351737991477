import {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import * as tf from "@tensorflow/tfjs";
import placePaper from "./images/scanning/place_paper.png";
import view1Image from "./images/scanning/view1.png";
import view2Image from "./images/scanning/view2.png";
import usageTracker, { UsageTrackerEvent } from "./UsageTracking/UsageTracker";
import { css } from '@emotion/css';

const styles = {
  container: css({
    position: 'absolute',
    marginLeft: 'auto',
    marginRight: 'auto',
    left: '0',
    right: '0'
  }),
  canvas: css({
    height: '100vh',
    width: '100%',
    objectFit: 'cover'
  }),
  overlay: css({
    position: 'absolute',
    marginLeft: 'auto',
    marginRight: 'auto',
    left: '0',
    right: '0',
    height: '100vh',
    width: '100%',
    objectFit: 'cover'
  }),
};

const enum CaptureStage {
  FindingPaper,
  View1,
  View2,
}

export type CaptureImages = [Promise<Blob>, Promise<Blob>];

const targets1 = [
  [0.35, 0.55, 0],
  [0.33, 0.12, 0],
  [0.75, 0.12, 0],
  [0.7, 0.55, 0],
];
const targets2 = [
  [0.13, 0.57, 0],
  [0.1, 0.24, 0],
  [0.65, 0.24, 0],
  [0.6, 0.57, 0],
];
const acceptPoseDistance = 100;

const validateCorners = (output: number[][], targets: number[][]) => {
  if (targets.length === 0) {
    return true;
  }
  const tfTargets = tf.tensor(targets).slice([0, 0], [4, 2]);
  let positions = tf.tensor(output).slice([0, 0], [4, 2]);
  let distance = tfTargets.sub(positions).abs().sum().arraySync();
  if (distance < acceptPoseDistance) {
    return true;
  }
  return false;
};

const getTargets = (stage: CaptureStage) => {
  switch (stage) {
    case CaptureStage.FindingPaper:
      return undefined;
    case CaptureStage.View1:
      return targets1;
    case CaptureStage.View2:
      return targets2;
  }
};

const getOverlay = (stage: CaptureStage) => {
  switch (stage) {
    case CaptureStage.FindingPaper:
      return placePaper;
    case CaptureStage.View1:
      return view1Image;
    case CaptureStage.View2:
      return view2Image;
  }
};

const transformTargets = (
  targets: number[][],
  scale: number,
  offset: number[]
) => {
  const transformed = targets.map((row) =>
    row.map((v, i) => v * scale + offset[i])
  );
  return transformed;
};

const hasAllCorners = (corners: number[][]) => corners.every((p) => p[2] > 0.9);

const drawCircle = (
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  p: number
) => {
  ctx.beginPath();
  ctx.arc(x, y, 10, 0, 2 * Math.PI, false);
  ctx.fillStyle = `rgb(${255 * (1 - p)}, ${255 * p}, 0)`;
  ctx.fill();
};

const drawCircles = (ctx: CanvasRenderingContext2D, array: number[][]) => {
  array.forEach((p) => drawCircle(ctx, p[0], p[1], p[2]));
};

interface CapturingProps {
  paperCornersRef: MutableRefObject<number[][]>;
  onCompletion: (images: CaptureImages) => void;
  height?: number;
  width?: number;
  screenGrab: () => Promise<Blob>;
}

export const Capturing: React.FC<CapturingProps> = (props) => {
  const [stage, setStage] = useState(CaptureStage.FindingPaper);
  const imageRef = useRef<Promise<Blob>>();
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const overlayRef = useRef<HTMLCanvasElement>(null);
  const imageOverlayRef = useRef<HTMLImageElement>();
  const frameRef = useRef(-1);
  const { paperCornersRef, onCompletion, height, width, screenGrab } = props;

  const updateStage = useCallback(() => {
    switch (stage) {
      case CaptureStage.FindingPaper:
        usageTracker.track(UsageTrackerEvent.paperDetected);
        setStage(CaptureStage.View1);
        break;
      case CaptureStage.View1:
        imageRef.current = screenGrab();
        usageTracker.track(UsageTrackerEvent.scannerShotTaken);
        setStage(CaptureStage.View2);
        break;
      case CaptureStage.View2:
        usageTracker.track(UsageTrackerEvent.scannerShotTaken);
        onCompletion([imageRef.current!, screenGrab()]);
        break;
    }
  }, [screenGrab, onCompletion, stage]);

  const onFrame = useCallback(() => {
    const ctx = canvasRef.current?.getContext("2d");
    const targets = getTargets(stage);
    const scale = window.innerHeight / canvasRef.current!.height;
    const xOffset = (window.innerWidth - window.innerWidth / scale) / 2;
    const yOffset = (window.innerHeight - window.innerWidth) / scale / 2;
    const transformedTargets = transformTargets(
      targets ?? [],
      window.innerWidth / scale,
      [xOffset, yOffset, 0]
    );
    const valid =
      hasAllCorners(paperCornersRef.current) &&
      validateCorners(paperCornersRef.current, transformedTargets);
    if (valid) {
      updateStage();
    }
    if (ctx) {
      ctx.clearRect(0, 0, canvasRef.current!.width, canvasRef.current!.height);
      drawCircles(ctx, paperCornersRef.current);
      drawCircles(ctx, transformedTargets);
    }
    frameRef.current = requestAnimationFrame(onFrame);
  }, [paperCornersRef, stage, updateStage]);
  useEffect(() => {
    frameRef.current = requestAnimationFrame(onFrame);
    return () => cancelAnimationFrame(frameRef.current);
  }, [onFrame]);

  useEffect(() => {
    const overlayCtx = overlayRef.current?.getContext("2d");
    imageOverlayRef.current = new Image();
    imageOverlayRef.current.src = getOverlay(stage);
    const scale = window.innerHeight / canvasRef.current!.height;
    const xOffset = (window.innerWidth - window.innerWidth / scale) / 2;
    const yOffset = (window.innerHeight - window.innerWidth) / scale / 2;
    imageOverlayRef.current.onload = (image) => {
      if (overlayCtx) {
        overlayCtx.clearRect(
          0,
          0,
          overlayRef.current!.width,
          overlayRef.current!.height
        );
        overlayCtx.drawImage(
          image.target as HTMLImageElement,
          xOffset,
          yOffset,
          window.innerWidth / scale,
          window.innerWidth / scale
        );
      }
    };
  }, [stage]);

  return (
    <div className={styles.container}>
      <canvas
        className={styles.canvas}
        ref={canvasRef}
        height={height}
        width={width}
      />
      <canvas
        className={styles.overlay}
        ref={overlayRef}
        height={height}
        width={width}
      />
      <button onClick={updateStage}>Click me!</button>
    </div>
  );
};
