import React, { useState, useRef, useCallback } from "react";
import { Onboarding } from "./Onboarding";
import { Scanning } from "./Scanning";
import { Results } from "./Results";
import { MeasurementsResults, Measurements } from "./Measurements";

const enum Stage {
  Onboarding,
  Scanning,
  Results,
}

const App: React.FC<{}> = () => {
  const [stage, setStage] = useState(Stage.Onboarding);
  const measurementsRef = useRef<Measurements>();

  const resultsCallback = useCallback((measurements?: MeasurementsResults) => {
    if (measurements?.ok) {
      measurementsRef.current = measurements.value;
      setStage(Stage.Results);
      return;
    }
    setStage(Stage.Onboarding);
  }, []);

  return (
    <div className="App">
      {stage === Stage.Onboarding && (
        <Onboarding
          onReady={() => {
            setStage(Stage.Scanning);
          }}
        />
      )}
      {stage === Stage.Scanning && (
        <Scanning resultsCallback={resultsCallback} />
      )}
      {stage === Stage.Results && (
        <Results measurements={measurementsRef.current!} />
      )}
    </div>
  );
};
export default App;
